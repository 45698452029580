<template>
  <v-card>
    <v-layout>
      <v-app-bar
        app
        class="align-center account-header py-4"
        color="#fff"
        fixed
      >
        <v-toolbar-title>
          <span @click="$router.push('/my-campaigns')" class="cursor-pointer">
            <img :src="pivotAdd" width="115" height="34" />
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon
          variant="text"
          @click="toggleMenu"
          style="justify-content: center; padding-right: 16px"
        ></v-app-bar-nav-icon>
      </v-app-bar>
      <v-navigation-drawer
        app
        temporary
        left
        :value="menuOpen"
        @input="menuOpen = $event"
        height="100%"
        class="drawer-width"
      >
        <!-- Your menu items go here -->
        <v-list>
          <div class="container">
            <div
              class="d-flex align-center justify-space-between mb-2 ml-2 mb-6"
            >
              <v-toolbar-title>
                <span
                  @click="$router.push('/my-campaigns')"
                  class="cursor-pointer"
                >
                  <img :src="pivotAdd" width="115" height="34" />
                </span>
              </v-toolbar-title>
              <div
                class="d-flex align-center mr-2"
                style="color: white !important"
              >
                <select-language-component :authorized="true" />
                <v-btn icon @click="menuOpen = !menuOpen">
                  <v-icon color="black">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="d-flex align-center ml-5 mt-6">
              <!--user image-->
              <div
                v-if="getUser.userAvatar"
                class="d-flex align-center"
                v-on="on"
              >
                <v-avatar name="Asilbek" class="userImage">
                  <img
                    :src="getImagePath(getUser.userAvatar)"
                    :alt="getUser.fullName"
                  />
                </v-avatar>
              </div>
              <div v-else>
                <v-avatar class="noImageStyle">
                  <div v-on="on">
                    {{ getFirstLetterOfName }}
                  </div>
                </v-avatar>
              </div>
              <!--user fullName-->
              <div class="d-block text-left ml-4 mb-2">
                <strong
                  class="font-family-raleway-bold"
                  style="font-size: 1.25rem"
                  >{{ getUser.fullName }}</strong
                >
                <p
                  class="pb-0 mb-0 font-family-raleway-medium"
                  style="font-size: 0.845rem"
                >
                  {{ getUser.email }}
                </p>
              </div>
            </div>
          </div>
          <!--my campaigns button-->
          <div class="container px-8">
            <v-list-item link>
              <v-list-item-title>
                <a
                  class="d-flex align-center"
                  @click="$router.push('/profile-settings')"
                >
                  <img :src="settingsImg" />
                  <span
                    class="ml-2 color-black text font-family-raleway-medium"
                    >{{ $t("authorized_page_header.settings") }}</span
                  ></a
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>
                <a
                  class="d-flex help-button-link"
                  @click="$router.push('/help')"
                >
                  <img :src="helpIcon" />
                  <span
                    class="ml-2 color-black text font-family-raleway-medium"
                    >{{ $t("authorized_page_header.help") }}</span
                  ></a
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>
                <a class="d-flex align-center" @click="handleLogout">
                  <img :src="logoutImg" alt="Logout Icon" />
                  <span
                    class="ml-2 color-black text font-family-raleway-medium"
                    >{{ $t("authorized_page_header.logout") }}</span
                  ></a
                >
              </v-list-item-title>
            </v-list-item>

            <v-divider class="my-2"></v-divider>
            <v-list-item link>
              <v-menu offset-y style="box-shadow: none" left>
                <template v-slot:activator="{ on }">
                  <v-list
                    style="background-color: transparent"
                    class="ma-0 pa-0"
                  >
                    <v-list-item class="pa-0 ma-0">
                      <v-icon>mdi-chevron-right</v-icon>
                      <v-list-item-action v-on="on" class="ma-0 pa-0 ml-1">
                        <div
                          class="d-flex align-center"
                          style="cursor: pointer"
                        >
                          <div class="font-family-raleway-medium">
                            <strong class="font-raleway-regular">{{
                              getSelectedAccountName
                            }}</strong>
                            <div
                              style="
                                color: #0057ff;
                                font-size: 10px;
                                font-weight: bold;
                              "
                            >
                              {{
                                numberWithSpaces(getSelectedAccountCustomerId)
                              }}
                            </div>
                          </div>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </template>

                <v-list style="width: 300px">
                  <v-list-item>
                    <v-list-item-title style="cursor: pointer">
                      <strong>{{
                        $t("authorized_page_header.my_accounts")
                      }}</strong>
                      <v-divider class="mt-2" />
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="(item, index) in accounts"
                    :key="index"
                    @click="handleClickAccount(item.id, item.customerId)"
                    dense
                  >
                    <v-list-item-title
                      class="d-flex align-center"
                      style="cursor: pointer"
                    >
                      <div class="font-weight-bold">
                        <strong class="font-family-raleway-medium">{{
                          item.accountName
                        }}</strong>
                        <div
                          style="color: #0057ff; font-size: 14px"
                          class="mt-1"
                        >
                          {{ numberWithSpaces(item.customerId.toString()) }}
                        </div>
                      </div>
                      <v-spacer></v-spacer>
                      <v-icon
                        v-if="accountId == item.customerId"
                        class="ml-2 mb-1 color-blue"
                        size="medium"
                      >
                        mdi-check-circle
                      </v-icon>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title style="cursor: pointer">
                      <strong>{{
                        $t("authorized_page_header.google_ads_accounts")
                      }}</strong>
                      <v-divider class="mt-2" />
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="(item, index) in getUser.googleAdsAccounts"
                    :key="index"
                    @click="
                      handleClickGoogleAds(
                        item.resourceName,
                        item.resourceName,
                        item
                      )
                    "
                    dense
                  >
                    <v-list-item-title
                      class="d-flex align-center"
                      style="cursor: pointer"
                    >
                      <div class="font-weight-bold">
                        <strong class="font-family-raleway-medium">{{
                          item.name
                        }}</strong>
                        <div style="color: gray; font-size: 10px" class="mt-1">
                          {{ numberWithSpaces(item.resourceName) }}
                        </div>
                      </div>
                      <v-spacer />
                      <v-icon
                        v-if="accountId == item.resourceName"
                        class="ml-2 mb-1 color-blue"
                        size="medium"
                      >
                        mdi-check-circle
                      </v-icon>
                      <v-btn
                        small
                        class="not-linked-style font-family-raleway-medium ml-2"
                        style="cursor: default"
                        v-if="item.manager"
                      >
                        <span style="color: #0057ff">MCC</span>
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-btn
                      small
                      style="width: 100%; border-radius: 10px !important"
                      to="/welcome"
                      class="font-family-raleway-bold button"
                      height="40"
                    >
                      <v-icon> mdi-plus</v-icon>
                      <span class="text-capitalize" style="font-size: 14px"
                        >add account</span
                      >
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title>
                <v-icon>mdi-chevron-right</v-icon>
                <router-link
                  class="font-family-raleway-medium text-decoration-none ml-1"
                  to="/my-campaigns"
                  active-class="color-primary"
                >
                  <span
                    :class="
                      getRouteName === 'MyCampaigns'
                        ? 'color-blue font-weight-bold'
                        : 'color-black'
                    "
                    >{{ $t("authorized_page_header.my_campaigns") }}</span
                  >
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>
                <v-icon>mdi-chevron-right</v-icon>
                <router-link
                  class="font-family-raleway-medium text-decoration-none ml-1"
                  to="/google-ads-account"
                  active-class="color-primary"
                >
                  <span
                    :class="
                      getRouteName === 'GoogleAdsAccount'
                        ? 'color-blue font-weight-bold'
                        : 'color-black'
                    "
                    >{{ $t("authorized_page_header.my_accounts") }}</span
                  >
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>
                <v-icon>mdi-chevron-right</v-icon>
                <router-link
                  class="font-family-raleway-medium text-decoration-none ml-1"
                  to="/billing"
                  active-class="color-primary"
                >
                  <span
                    :class="
                      getRouteName === 'Billing'
                        ? 'color-blue font-weight-bold'
                        : 'color-black'
                    "
                    >{{ $t("authorized_page_header.billing") }}</span
                  >
                </router-link></v-list-item-title
              >
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>
    </v-layout>
  </v-card>
</template>
<script>
import pivotAdd from "../../assets/icon/Pivotadd_black.svg";
import helpIcon from "../../assets/icon/help.svg";
import settingsImg from "../../assets/img/Settings.svg";
import logoutImg from "../../assets/img/log-out.svg";
import { accountService } from "@/services/account";
import { baseUrl } from "@/main";
import { mapGetters } from "vuex";
import mccLinkIcon from "../../assets/icon/mcc-link.svg";

export default {
  name: "Header",
  components: {
    SelectLanguageComponent: () =>
      import("@/components/SelectLanguageComponent"),
  },
  data: () => ({
    pivotAdd,
    helpIcon,
    settingsImg,
    logoutImg,
    accountName: "",
    accounts: [],
    user: {},
    accountId: "",
    mccLinkIcon,
    menuOpen: false,
  }),
  async created() {
    const userId = localStorage.getItem("userId");
    this.user = this.getUser;
    const res = await accountService.getByUserId(userId);
    this.accounts = res.data;

    const cusId = localStorage.getItem("customerId");
    if (cusId) {
      this.accountId = cusId;
    } else {
      if (res?.data.length > 0) {
        localStorage.setItem("customerId", res?.data[0]?.customerId);
        this.accountId = res?.data[0]?.customerId;
      } else {
        await this.$router.push("/welcome");
      }
    }
  },
  computed: {
    ...mapGetters(["getUser"]),
    getRouteName() {
      return this.$route.name || "";
    },

    getFirstLetterOfName() {
      return this.getUser?.fullName?.charAt(0).toUpperCase();
    },

    getSelectedAccountName() {
      return (
        this.accounts?.find((item) => item.customerId == this.accountId)
          ?.accountName ||
        this.getUser?.googleAdsAccounts?.find(
          (item) => item.resourceName == this.accountId
        )?.name ||
        this.$t("authorized_page_header.select_account")
      );
    },
    getSelectedAccountCustomerId() {
      return (
        this?.accounts
          ?.find((item) => item.customerId == this.accountId)
          ?.customerId.toString() || ""
      );
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    getImagePath(url) {
      return baseUrl + "/" + url;
    },
    handleLogout() {
      localStorage.clear();
      this.$router.push("/");
    },
    handleClickAccount(accountId, customerId) {
      localStorage.setItem("accountId", accountId);
      localStorage.setItem("customerId", customerId);
      this.accountId = customerId;
      window.location.reload();
    },
    handleClickGoogleAds(accountId, customerId, item) {
      if (!item.manager) {
        localStorage.setItem("accountId", accountId);
        localStorage.setItem("customerId", customerId);
        this.accountId = customerId;
        window.location.reload();
      }
    },
    numberWithSpaces(val) {
      let phone = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
      return phone.replaceAll(" ", "-");
    },
  },
};
</script>

<style scoped>
.account-header {
  height: 75px !important;
  box-shadow: none !important;
  border-bottom: 1px solid #dedbe3 !important;
}

.noImageStyle {
  border-radius: 25px !important;
  width: 75px !important;
  height: 75px !important;
  background: #150da8;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin: 0;
}

.userImage {
  object-fit: cover;
  border-radius: 25px !important;
  width: 75px !important;
  height: 75px !important;
}
.drawer-width {
  width: 100% !important;
}
@media screen and (min-width: 450px) and (max-width: 960px) {
  .drawer-width {
    width: 375px !important;
  }
}
</style>
